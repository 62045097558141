import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';


const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
});

class AuthService {
    login(user) {
        var data = new FormData();
        data.append('email', user.email);
        data.append('password', user.password);
        return axios
            .post(API_ENDPOINT + 'api/auth/login', data)
            .then((response) => {
                if (response.status == 200) {
                    setItem('access_token', JSON.stringify(response.data.access_token));
                    setItem('usr_role', JSON.stringify(response.data.role_id));
                    setItem('user1', JSON.stringify(response.data.user_id));
                    return response;
                } else {
                    return response;
                }
            });
    }

    logout() {
        var data = new FormData();
        return axios
            .post(API_ENDPOINT + 'api/auth/logout', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // alert('logout');
                    removeItem('user');
                    removeItem('access_token');
                    removeItem('syncdata');
                    removeItem('org');
                    return response;
                } else {
                    return response;
                }
            });
    }
    
    updateProfile(user) {
        console.log(user.photo);
        var bodyFormData = new FormData();
        bodyFormData.append("f_name", user.f_name);
        bodyFormData.append("l_name", user.l_name);
        bodyFormData.append("user_id", user.id);
        bodyFormData.append("email", user.email);
        bodyFormData.append("password", user.password);
        bodyFormData.append("password_confirmation", user.confirm_password);
        bodyFormData.append("selectedFile", user.photo);
        return axios({
            method: "post",
            url: API_ENDPOINT + 'api/auth/updateProfile',
            data:bodyFormData,
            headers: { "Content-Type": "application/json",Authorization:  `Bearer ${getItem('access_token')}` },
          })
        // return axios
        // .post(API_ENDPOINT + 'api/auth/updateProfile', {
        //     f_name: user.f_name,
        //     l_name: user.l_name,
        //     email: user.email,
        //     profile_pic: user.photo,
        //     password_confirmation: user.confirm_password,
        //     user_id:user.id,
        //     password: user.password
        // }, {
        //     headers: { Authorization: `Bearer ${getItem('access_token')}`,"Content-Type": "application/json", }
        // })
        .then((response) => {
            if (response.status == 200) {
                setItem('access_token', JSON.stringify(response.data.access_token));
                return response;
            } else {
                return response;
            }
        });
    }
    signup(user) {
        console.log(user);
        return axios
        .post(API_ENDPOINT + 'api/auth/signup', {
            f_name: user.f_name,
            l_name: user.l_name,
            email: user.email,
            user_id: user.u_name,
            team_name: user.t_name,
            password: user.password
        })
        .then((response) => {
            if (response.status == 200) {
                setItem('access_token', JSON.stringify(response.data.access_token));
                return response;
            } else {
                return response;
            }
        });
    }

    register(user) {
        console.log(user);
        return axios.post(API_ENDPOINT + 'signup', {
            name: user.name,
            username: user.username,
            email: user.email,
            user_id: user.user_id,
            password: user.password
        });
    }

    

    userData() {
        var data = new FormData();
        return axios
            .get(API_ENDPOINT + 'api/auth/user-profile', {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    setItem('user', JSON.stringify(response.data));
                    return response;
                } else {
                    removeItem('user');
                    removeItem('access_token');
                    return response;
                }
            });
    }


}

export default new AuthService();
