import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {
async currentLeadsReport({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.currentleadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async inboundLeadsReport({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.inboundleadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  }, 
}
export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
