import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {
async followUpsLeadsReport({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.followupleadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async clicksTagReport({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.clickstagdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async callsTagReport({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.callstagdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },

}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
